import React from "react";
import {DataStore} from "@aws-amplify/datastore";
import {Databases} from "./models";
import {useEffect, useState} from "react";

//const Home = () => {
function Home() {
    /*const [databases, setDatabases] = useState([])

    const fetchDatabases = useCallback(async () => {
        setDatabases(await DataStore.query(Databases))
    }, [])

    useEffect(() => {
        async function fetchDatabases() {
            setDatabases(await DataStore.query(Databases))
            console.log(databases)
        }

        fetchDatabases().then(r => null);
    }, []);

    const GotoDatabaseLandingPage = (props) => {
        const v = "/landing/" + props.name + ".js";
        alert("Going to " + v);
        window.location.href = v;
    }

    function DatabasesRecord(props) {
        return (
            <button className="App-databasebuttons"
                    onClick={() => GotoDatabaseLandingPage(props)}>{props.prettyname}</button>
        );
    }*/

    return (

        <p>asdddfafa</p>
        /*<div className="App">
            <header className="App-header">
                <h1>Process Data Management System</h1>
            </header>
            <div className="App-content">
                <br/>
                <h3>Select a database...</h3>
                <br/>
                {databases.map(database => DatabasesRecord(database))}
            </div>
        </div>*/
    );
}

export default Home;