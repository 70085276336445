import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Foil from "./landing/Foil"
import FoilSurfaceTreatment from "./landing/FoilSurfaceTreatement"
import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Home from "./Home";

Amplify.configure(awsconfig)

//https://reactrouter.com/docs/en/v6/upgrading/v5
//https://www.geeksforgeeks.org/how-to-redirect-to-another-page-in-reactjs/

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" component={Home}/>
            </Switch>
        </Router>
    );
}

/*
<Route path="/landing" component={Foil}/>
<Route path="/landing" component={FoilSurfaceTreatment}/>
*/
export default App